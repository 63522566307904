<template>
  <div>
    <div class="title">
      <div class="container box-series" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
        <div class="iconTextWrapper">
          <img src="@/assets/icons/back.svg" v-if="localDisplayLang === 'eng'" alt @click="initialRoute" />
          <img src="@/assets/icons/back.svg" class="rotate-img" v-else @click="initialRoute" alt />
          &nbsp;&nbsp;&nbsp;
          <p style="margin: 0px 10px">{{ $t(this.title) }}</p>
        </div>
      </div>
    </div>
    <!-- <div v-if="loader">
      <div v-for="(item, i) in 4" :key="i">
        <categoriesPlaceholder></categoriesPlaceholder>
      </div>
    </div> -->

    <div v-for="(item, index) in categoryScreens" :key="index">
      <!-- <slider :screen="item" :key="title" :id="index" :playerInstance="playerInstance" :screenName="screens.id"></slider> -->
			<slider v-if="(item.sectionType != 'FEATURED' && item.listType != 'CAROUSEL')" :screen="item" :key="title" :id="index" :playerInstance="playerInstance" :screenName="screens.id" ></slider>
    </div>

     <intersect :key="title" @enter="inters">
        <div></div>
      </intersect>

    <!-- <menuTemplate :screens="screens" :menu="menu" :playerInstance="playerInstance"></menuTemplate> -->

    <!-- <div class="grid-view" v-else>
      <noorPlayGrid
        :contents="screenContent"
        :section="screen"
        :playerInstance="playerInstance"
        :message="errorMessage"
        :isScrollable="isScrollable"
        @loadMore="loadMoreContents()"
      ></noorPlayGrid>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "@/eventBus";
import Intersect from "vue-intersect";
export default {
  props: {
    menuDetail: {
      type: Object,
    },
    section: {
      type: [Object, String],
    },
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      loader: true,
      screenContent: [],
      errorMessage: null,
      localDisplayLang: null,
      page: 1,
      isScrollable: false,
      isMpegRequired: false,
      sectionScreen: null,
      menu: null,
      screens: null,
      categoryScreens: [],
      title: null,
      lowCounter: 0,
      highCounter: 1,
      multiplyValue: 3,
      screensList:[]
    };
  },
  computed: {
    ...mapGetters(["appConfig"]),
  },
  watch: {
    $route() {
      console.log("route change");
      this.screenContent = [];
      this.screensList = [];
      this.page = 1;
      this.title = this.$router.currentRoute.params.section.toLowerCase();
      this.lowCounter = 0;
      this.highCounter = 1;
      this.multiplyValue = 3;
      this.categoryScreens = [];
      // Refresh All the Contents
      this.getContentScreen();
    },
  },
  created() {
    // The Section Screen
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.getContentScreen();

    this.title = this.$router.currentRoute.params.section;
  },
  mounted() {

  },
  methods: {
    inters(){
      console.log('Call the next set data using intersection api from section wise listing');
      this.callNextSetCategories();
    },
    callNextSetCategories(){
        let nextSetData = this.getSlicedSections(this.screensList);
        this.passDataToCarousal(nextSetData);
    },
    checkMpegRequired() {
      let isMpegRequired = false;
      let videoTag = document.createElement("video");
      let platformOs = navigator.platform;

      if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          isMpegRequired = true;
        }
      }

      return isMpegRequired;
    },
		getContentScreenDecking () {
      let deckingconfig = localStorage.getItem("appDeckingConfig") ? JSON.parse(localStorage.getItem("appDeckingConfig")) : '';
      let allMenuList = [...deckingconfig.screens]
      allMenuList.forEach((ele) => {
        if ((ele.id.replaceAll(' ','')).toLowerCase().includes((this.$router.currentRoute.params.section).toLowerCase())) {
          this.sectionScreen = ele.id;
        }
      });
         if (this.sectionScreen) {
        this.menu = this.sectionScreen;
        let index = allMenuList.findIndex((element) => {
          return this.menu === element.id;
        });
        if (index > -1) {
          this.screens = allMenuList[index];
        }
      }
      for (let singleItem of this.screens.sections) {
        if (singleItem.status == 'ACTIVE') {
          this.screensList.push(singleItem) 
        }
        
      }
      // this.screensList = this.screens.sections;
      console.error("this.screensList", this.screensList)
      let finalSectionsData = this.getSlicedSections(this.screensList);
      this.passDataToCarousal(finalSectionsData);
    },

    loadTemplate(menu) {
      console.log("THE MENU , --- ", menu);

      if (menu) {
        this.menu = menu;

        console.log("THE NEW NEW NEW ", this.appConfig.screens, menu);

        let index = this.appConfig.screens.findIndex((element) => {
          return this.menu === element.id;
        });

        if (index > -1) {
          this.screens = this.appConfig.screens[index];

          console.log("this.screens", this.screens);
        }
      }
    },

    //to get the screens from appconfig object.
    checkMpegRequired() {
      let isMpegRequired = false;
      let videoTag = document.createElement("video");
      let platformOs = navigator.platform;

      if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          isMpegRequired = true;
        }
      }

      return isMpegRequired;
    },
    getContentScreen() {
      console.log("ROUTER PARAM LINK -- ", this.appConfig.menu);
			if (this.appConfig.featureEnabled && this.appConfig.featureEnabled.isDeckingEnabled) {
        this.getContentScreenDecking();
        return;
      }

      this.appConfig.menu.forEach((ele) => {
        console.log("THE NEW PARAMS --- ", this.$router.currentRoute.params, ele);
        if ((ele.id).toLowerCase() === this.$router.currentRoute.params.section) {
          this.sectionScreen = ele.id;
        }
      });

      this.loadTemplate(this.sectionScreen);

      this.screensList = this.screens.sections;
      let finalSectionsData = this.getSlicedSections(this.screensList);
      this.passDataToCarousal(finalSectionsData);
    },
    getSlicedSections(sections) {
      let finalSections = sections.slice(
        this.lowCounter * this.multiplyValue,
        this.highCounter * this.multiplyValue
      );
      this.lowCounter = this.lowCounter + 1;
      this.highCounter = this.highCounter + 1;
      
      return finalSections;
    },

    passDataToCarousal(data) {
      data.forEach((element) => {
        this.categoryScreens.push(element);
      });
    },
    getAllContents(isMpegRequired) {
      let payload = {
        endpoint: this.screen.endpoint,
        params: this.screen.parameters,
      };
      payload.params.displaylanguage = this.localDisplayLang;
      payload.params.page = this.page;

      if (isMpegRequired == true) {
        payload.params.mpegtspackage = "YES";
      }

      let subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails");

      if (subscriberProfileDetails) {
        subscriberProfileDetails = JSON.parse(subscriberProfileDetails);
      }

      if (subscriberProfileDetails && subscriberProfileDetails.kidsmode === "YES") {
        payload.params.genre = "Kids";
      }

      eventBus.$emit("grid-see-all-data", payload);
    },
    initialRoute() {
this.$router.replace({ name: "Home", params: { lang: this.$i18n.locale == 'ara' ? 'ar' : 'en'}});
      //this.$router.push({ name: "Home" });
    },
    loadMoreContents() {
      this.page = this.page + 1;
      this.getAllContents(this.isMpegRequired);
    },
  },
  components: {
    Intersect,
    categoriesPlaceholder: () =>
      import(/* webpackChunkName: "categoriesPlaceholder" */ "@/components/placeholders/categoriesPlaceholder.vue"),
    noorPlayGrid: () =>
      import(/* webpackChunkName: "categoriesPlaceholder" */ "@/components/Templates/noorPlayGrid.vue"),
    menuTemplate: () => import(/* webpackChunkName: "menuTemplate" */ "@/components/Templates/menuTemplate.vue"),
    slider: () => import(/* webpackChunkName: "carousel" */ "@/components/categorySlider/slider.vue"),
  },
  beforeDestroy() {
    this.screenContent = [];
    eventBus.$off("check-for-series-route");
    eventBus.$off("get-seeAll-data");
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
.title {
  // margin-top: 50px;
  background: linear-gradient(90deg, #1d1d1d 0%, #1a1a1a 100%);
  .box-series {
    display: flex;
    .iconTextWrapper {
      display: flex;
      img {
        cursor: pointer;
        width: 1rem;
        opacity: 0.5;
        // &:hover {
        //   opacity: 1;
        // }
      }
      .rotate-img {
        transform: rotate(180deg);
      }
      p {
        opacity: 1;
        color: #848486;
        font-family: $font-regular;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 1.5rem;
        // &:hover{
        //    color: $clr-light-gd3;
        // }
      }
      &:hover {
        img {
          opacity: 1;
        }
        p {
          color: $clr-light-gd3;
        }
      }
    }
  }
}
.grid-view {
  height: 100%;
}
</style>
